(function($) {

  var searchForm = $('.nav-secundary > .search-form');
  searchForm.addClass('hidden');

  $('#search-toggle').click(function(e){
    e.preventDefault();
    if (searchForm.hasClass('hidden')) {
      searchForm.removeClass('hidden');
      $('.search-field').focus();
    } else {
      searchForm.addClass('hidden');
    }
  });

})(jQuery);
